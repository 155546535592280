"use client"
import { useRouter } from 'next/navigation'
import React from 'react'

const ErrorAlert = ({ error }: any) => {
    const router = useRouter();
    const getErrorMessage = (error: any) => {
        if (error === "AccessDenied") return "Access Denied";
        if (error === "InvalidCredentials") return "Invalid Credentials";
        if (error === "InvalidBody") return "Invalid Body";
    }
    const clearMessage = () => {
        router.replace("/auth/login")
    }
    return (
        <>  {error && <div>
            <span>{getErrorMessage(error)}</span>
            <button onClick={clearMessage}>x</button>
        </div>}</>
    )
}

export default ErrorAlert